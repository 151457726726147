import fetch, { RequestInit } from 'node-fetch'

import { ValidationError, ValidationException } from './validation'

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

interface IApiRequestOptions{
  action?: string
  headers?: any
  query?: any
  body?: any
  formData?: any
}

export class ApiService {
  static headers: Record<string, string> = {}
  static accessToken: string|undefined
  static renewAccessToken: () => Promise<boolean>

  static create(
    baseUrl: string
  ){
    return class _API {
      public controller: string
      public version?: number
      public url: string

      constructor(
        controller: string,
        version?: number
      ){
        this.controller = controller
        this.version = version
    
        let url = baseUrl
        if(version != null){
          url += `/v${version}`
        }
        if(controller != null){
          url += `/${controller}`
        }
        this.url = url
      }
    
      private async _request<TResponse>(
        method: RequestMethod,
        options?: IApiRequestOptions
      ): Promise<TResponse>{
        let url = this.url
        if(options?.action != null){
          url += `/${options.action}`
        }
        let fetchUrl = new URL(url)
    
        let headers = options?.headers ?? {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
        if(ApiService.accessToken != null){
          headers['Authorization'] = `Bearer ${ApiService.accessToken}`
        }
        let fetchOptions: RequestInit = {
          method,
          headers: {
            ...ApiService.headers,
            ...headers,
          }
        }
        if(options?.query != null){
          Object.keys(options.query).forEach(key => {
            let queryValue = options.query[key]
            let values = Array.isArray(queryValue) ? queryValue : [queryValue]
            for(let value of values){
              if(value === undefined){
                continue
              }
              if(value instanceof Date){
                value = value.toISOString()
              }
              fetchUrl.searchParams.append(key, value)
            }
          })
        }
        if(options?.body != null){
          fetchOptions.body = JSON.stringify(options.body)
        }
        else if(options?.formData != null){
          fetchOptions.body = options.formData
        }
        
        const responseHttp = await fetch(fetchUrl, fetchOptions)
        const responseJson = await responseHttp.json()
        if(responseJson.$validation == true){
          if(responseJson.authentication?.code == 401){
            const success = await ApiService.renewAccessToken()
            if(success){
              return this._request<TResponse>(method, options)
            }
          }
          throw new ApiValidationException(responseJson)
        }
        return responseJson as TResponse
      }
      private async _requestForm<TResponse>(
        method: RequestMethod,
        action?: string,
        body?: any,
        options?: IApiRequestOptions
      ){
        const formData = new FormData()
        for(let key in body){
          const value = body[key]
          if(Array.isArray(value)){
            Object.keys(value).forEach(arrayKey => {
              const arrayValue = (value as any)[arrayKey]
              formData.append(`${key}[${arrayKey}]`, arrayValue)
            })
          }
          else {
            formData.append(key, value)
          }
        }
        return this._request<TResponse>(
          method, { action, formData, headers: {
            'Accept': 'application/json',
          }, ...options }
        )
      }
    
      public get<TResponse>(
        action?: string,
        query?: any
      ){
        return this._request<TResponse>(
          'GET', { action, query }
        )
      }
      public async getByKey<TResponse>(
        id: string,
        query?: any
      ){
        return (await this.get<TResponse[]>(undefined, {
          ...query,
          'id': id,
        }))[0]
      }
    
      public post<TResponse>(
        action: string|undefined,
        body?: any,
        options?: IApiRequestOptions
      ){
        return this._request<TResponse>(
          'POST', { action, body, ...options }
        )
      }
      public postForm<TResponse>(
        action: string,
        body: any,
        options?: IApiRequestOptions
      ){
        return this._requestForm<TResponse>('POST', action, body, options)
      }
    
      public put<TResponse>(
        action: string|undefined,
        body: any,
        options?: IApiRequestOptions
      ){
        return this._request<TResponse>(
          'PUT', { action, body, ...options }
        )
      }
      public putForm<TResponse>(
        action: string,
        body: any,
        options?: IApiRequestOptions
      ){
        return this._requestForm<TResponse>('PUT', action, body, options)
      }

      public httpDelete<TResponse>(
        action?: string,
        query?: any
      ){
        return this._request<TResponse>(
          'DELETE', { action, query }
        )
      }
    
    }
  }

}

export class ApiValidationError extends ValidationError{
  code: number
  constructor(
    code: number,
    message: string,
    args?: any
  ){
    super(message, args)
    this.code = code
  }
}
export class ApiValidationException extends ValidationException {
  constructor(payload: any){
    delete payload.$validation
    const apiErrors: {[key: string]: ApiValidationError} = {}
    for(const key in payload){
      const value = payload[key]
      apiErrors[key] = new ApiValidationError(
        value.code, value.message, value.args
      )
    }
    super(apiErrors)
  }
}