import { Exception } from './exception'

type TRuleLogic = (value: any) => false|ValidationError
type TRuleWrapper = (...args: any[]) => TRuleLogic

export class Validation<TValidate> {
  
  private _validatedProperties: string[] = []

  public readonly Rule: { [key: string]: TRuleWrapper } = {
    Required: () => {
      return (value: any) => {
        const valid = value != null && value !== ''
        return !valid && new ValidationError('of.error.required')
      }
    },
    LengthMin: (characters: number) => {
      return (value: any) => {
        const valid = value.length >= characters
        return !valid && new ValidationError('of.error.length_min', { characters })
      }
    },
    LengthMax: (characters: number) => {
      return (value: any) => {
        const valid = value.length <= characters
        return !valid && new ValidationError('of.error.length_max', { characters })
      }
    },
    Email: () => {
      return (value: any) => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i
        const valid = regex.test(value)
        return !valid && new ValidationError('of.error.email_format')
      }
    },
    Number: () => {
      return (value: any) => {
        const valid = !isNaN(value)
        return !valid && new ValidationError('of.error.numeric_only')
      }
    },
    GreaterThan: (compare: number) => {
      return (value: any) => {
        const valid = value > compare
        return !valid && new ValidationError('of.error.greater_than', { compare })
      }
    },
  }

  validate(obj: Partial<TValidate>, rules: { [key in keyof TValidate]: TRuleLogic[]|null }){
      let errors: { [key: string]: ValidationError } = {}
      for(const property in rules){
        this._validatedProperties.push(property)
        const ruleLogics = rules[property]
        if(ruleLogics != null){
          const objValue = obj[property]
          for(const ruleLogic of ruleLogics as Function[]){
            let ruleResult = ruleLogic(objValue)
            if(ruleResult){
              errors[property] = ruleResult
              break
            }
          }
        }
      }
      return new ValidationException(errors)
  }

  count(errors: object|undefined){
    if(errors == null) return 0
    return Object.keys(errors).length
  }

  throw(exception: object){
    const unknownFormError = {
      '_form': new ValidationError('of.error.unknown')
    }
    if(exception instanceof ValidationException){
      const hasUnhandledProperties = Object.keys(exception)
        .some(property => !this._validatedProperties.includes(property))
      if(hasUnhandledProperties){
        console.error('unhandled', exception)
        exception = {
          ...unknownFormError,
          ...exception,
        }
      }
      if(this.count(exception) > 0){
        throw exception
      }
    }
    else{
      console.error('unknown', exception)
      throw unknownFormError
    }
  }

}


export class ValidationError {
  message: string
  args?: Object

  constructor(message: string, args?: Object){
    this.message = message
    this.args = args
  }
}
export class ValidationException extends Exception<ValidationError>{}