<template>
  <div id="CategoryIndex" class="container container-xxl">
    <div class="clearfix">
      <div class="float-left">
        <h3>Categories</h3>
      </div>
      <div class="float-right">
        <router-link
          class="btn btn-primary"
          to="/category/add">
          <fa-icon icon="plus" /> New
        </router-link>
      </div>
    </div>
    <hr />
    <grid
      section="/v1/" schema="Category" action=""
      default-order="name asc">
      <template v-slot:thead>
        <tr>
          <grid-control
            property="id"
            type="text">ID</grid-control>
          <grid-control
            property="identifier"
            type="text">Identifier</grid-control>
          <grid-control
            property="name"
            type="text">Name</grid-control>
          <grid-control rowspan="2" />
        </tr>
      </template>
      <template v-slot:tbody="data">
        <tr
          v-for="row in data.rows"
          v-bind:key="`category_${row.id}`">
          <td>{{row.id}}</td>
          <td>{{row.identifier}}</td>
          <td>{{row.name}}</td>
          <td class="cell-fit">
            <RouterLink
              class="btn btn-sm btn-primary btn-cell"
              :to="{
                name: 'category-edit',
                params: { id: row.id }
              }">
              <fa-icon icon="edit" />
            </RouterLink>
          </td>
        </tr>
      </template>
    </grid>

  </div>
</template>

<script>
export default {
  name: 'CategoryIndex',
}
</script>
