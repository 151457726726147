<template>
  <div id="PromoIndex" class="container container-xxl">
    <div class="clearfix">
      <div class="float-left">
        <h3>Promo</h3>
      </div>
      <div class="float-right">
        <router-link
          class="btn btn-primary"
          to="/promo/add">
          <fa-icon icon="plus" /> New
        </router-link>
      </div>
    </div>
    <hr />
    <grid
      section="/v1/" schema="Premium" action="Promo"
      default-order="created desc">
      <template v-slot:thead>
        <tr>
          <th colspan="3"></th>
          <th colspan="2">Valid</th>
          <th colspan="1">Apple</th>
          <th colspan="3">Google</th>
        </tr>
        <tr>
          <grid-control type="text" property="id">ID</grid-control>
          <grid-control type="text" property="code">Code</grid-control>
          <grid-control type="text" property="tier">Tier</grid-control>
          <grid-control type="date" property="validFrom">From</grid-control>
          <grid-control type="date" property="validTo">To</grid-control>
          <grid-control type="text" property="appleCode">Offer Code</grid-control>
          <grid-control type="text" property="googleProduct">Product ID</grid-control>
          <grid-control type="text" property="googleBaseplan">Base Plan ID</grid-control>
          <grid-control type="text" property="googleOffer">Offer ID</grid-control>
        </tr>
      </template>
      <template v-slot:tbody="data">
        <tr
          v-for="row in data.rows"
          v-bind:key="`promo_${row.id}`">
          <td>{{row.id}}</td>
          <td>{{row.code}}</td>
          <td>{{row.tier}}</td>
          <td>{{formatDate(row.validFrom)}}</td>
          <td>{{formatDate(row.validTo)}}</td>
          <td>{{row.appleCode}}</td>
          <td>{{row.googleProduct}}</td>
          <td>{{row.googleBaseplan}}</td>
          <td>{{row.googleOffer}}</td>
        </tr>
      </template>
    </grid>
  </div>
</template>

<script>
export default{
  methods: {
    formatDate(value){
      return new Date(value).toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    }
  }
}
</script>