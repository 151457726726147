import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  /* Grid */
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faFilter,
  faChevronUp,
  faChevronDown,
  /* Grid */
  faArrowLeft,
  faArrowRight,
  faPlus,
  faMinus,
  faEdit,
  faTimes,
  faClipboard,
  faRedo,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faFilter,
  faChevronUp,
  faChevronDown,

  faArrowLeft,
  faArrowRight,
  faPlus,
  faMinus,
  faEdit,
  faTimes,
  faClipboard,
  faRedo,
)

export default FontAwesomeIcon