// @ts-nocheck
import Vue from 'vue'
import bastiandevGrid from 'bastiandev-grid/index'
import { BootstrapVue } from 'bootstrap-vue'
import VueErrorHandler from '@/plugins/error-handler'
import VueParticles from 'vue-particles'
import VueUUID from 'vue-uuid'

import App from './App.vue'
import RouterPlugin from '@/plugins/router'
import _API from '@/services/_'

import FontAwesomeIcon from '@/plugins/icons.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/site.css'

const baseUrl = new _API().url

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(bastiandevGrid, { baseUrl })
Vue.use(VueErrorHandler)
Vue.use(VueParticles)
Vue.use(VueUUID)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.mixin({
  computed: {
    $baseUrl() {
      return baseUrl
    }
  }
})

new Vue({
  router: RouterPlugin,
  render: h => h(App)
}).$mount('#app')
