<template>
  <form v-on:submit.prevent="$emit('submit')">
    <div class="form-group">
      <label>Datum</label>
      <date-range-picker
        v-model="value"
        v-bind:single="true"></date-range-picker>
    </div>
  </form>
</template>
<script>
  export default {
    data() {
      return {
        value: null
      }
    }
  }
</script>
