<template>
  <div class="row">
    <div class="col-6">
      <template v-if="entity != null">
        <div 
          key="circle-buttons-exist"
          v-if="circle != null">
          <div class="clearfix">
            <div class="float-left">
              <h5>Circle <small>{{ circle.created }}</small></h5>
            </div>
            <div class="float-right">
              <span
                class="badge badge-pill badge-primary"
                v-if="entity.circleID == circle.id">
                Active
              </span>
            </div>
          </div>
          <button
            class="btn btn-primary btn-sm mr-1"
            :disabled="circleIndex == circles.length - 1"
            @click="showCircle(circles[++circleIndex])">
            <fa-icon icon="arrow-left" />
          </button>
          <button
            class="btn btn-success btn-sm mr-1"
            @click="onClickCircleNew">
            <fa-icon icon="plus" />
          </button>
          <button
            class="btn btn-primary btn-sm mr-1"
            :disabled="circleIndex == 0"
            @click="showCircle(circles[--circleIndex])">
            <fa-icon icon="arrow-right" />
          </button>
        </div>
        <div v-else>
          <h5>New Circle</h5>
          <button
            key="circle-buttons-new"
            class="btn btn-danger btn-sm mr-1"
            @click="onClickCircleCancel">
            <fa-icon icon="times" /> Cancel
          </button>
        </div>
        <hr />
      </template>

      <h5>Preview</h5>
      <div class="row">
        <div class="col-6">
          <CircleDisplay
            :color-background="form.colorBackground"
            :image="preview" />
        </div>
        <div class="col-6">
          <CircleDisplay
            :color-background="form.colorBackground"
            :color-text="form.colorText"
            :text="form.fallback"
            size-text="40pt" />
        </div>
      </div>

      <template v-if="circle == null">
        <h5 class="mt-4">Logo Image <small>(svg)</small></h5>
        <b-form-file
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          v-model="form.logo" />

        <div class="row">
          <div class="col-6">
            <h5 class="mt-4">Color Text <small>(hex)</small></h5>
            <ColorPicker v-model="colorPickerText" />
          </div>
          <div class="col-6">
            <h5 class="mt-4">Color Background <small>(hex)</small></h5>
            <ColorPicker v-model="colorPickerBackground" />
          </div>
        </div>
      </template>
      <div v-else>
        <table class="table table-bordered mt-3">
          <tr>
            <th>Circle ID:</th>
            <td>{{circle.id}}</td>
          </tr>
          <tr>
            <th>Color Background:</th>
            <td>{{circle.colorBackground}}</td>
          </tr>
          <tr>
            <th>Color Text:</th>
            <td>{{circle.colorText}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-6">
      <form @submit.prevent>
        <div class="form-group">
          <label>Name</label>
          <input
            type="text"
            class="form-control"
            v-model="form.name" />
        </div>
        <div class="form-group">
          <label>Identifier</label>
          <input
            type="text"
            class="form-control"
            :disabled="entity != null"
            v-model="form.identifier" />
        </div>
        <div class="form-group">
          <label>Fallback</label>
          <input
            type="text"
            class="form-control"
            v-model="form.fallback" />
        </div>
        <div class="form-group">
          <label>Keywords (Regex)</label>
          <BFormTags
            input-id="tags-pills"
            v-model="form.keywords"
            tag-variant="primary"
            tag-pills
            size="lg"
            placeholder="Enter keyword and press enter"
            :tag-validator="keywordValidator" />
        </div>
        <div>
          <label>Flags</label>
          <b-form-checkbox v-model="form.isRecurring">
            Is recurring
          </b-form-checkbox>
          <b-form-checkbox v-model="form.isPayment">
            Is a payment processor
          </b-form-checkbox>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Chrome as ColorPicker } from 'vue-color'

import CircleService from '@/services/circle'

import CircleDisplayComponent from '@/components/circle-display'

export default {
  components: {
    'ColorPicker': ColorPicker,
    'CircleDisplay': CircleDisplayComponent,
  },
  props: ['entity'],
  created(){
    this.showCircle(this.circles[0])
  },
  data(){
    return {
      preview: null,
      colorPickerText: null,
      colorPickerBackground: null,
      form: {
        name: this.entity?.name || '',
        identifier: this.entity?.identifier || '',
        fallback: this.entity?.fallback || '',
        keywords: this.entity?.vendorKeywords.map(vendorKeyword => {
          return vendorKeyword.value
        }) || [],
        colorBackground: null,
        colorText: null,
        logo: null,
        isRecurring: this.entity?.isRecurring || false,
        isPayment: this.entity?.isPayment || false,
      },
      circle: null,
      circleIndex: 0,
      circles: this.entity?.circles.sort((a, b) => {
        return new Date(b.created) - new Date(a.created)
      }) || []
    }
  },
  watch: {
    'colorPickerText'(newValue){
      this.form.colorText = newValue.hex.toLowerCase()
    },
    'colorPickerBackground'(newValue){
      this.form.colorBackground = newValue.hex.toLowerCase()
    },
    'form.name'(newValue){
      if(this.entity == null){
        this.form.identifier = newValue
          .trim()
          .toLowerCase()
          .replace(/ä/g, 'ae')
          .replace(/ö/g, 'oe')
          .replace(/ü/g, 'ue')
          .replace(/\W+/g, '_')
      }
    },
    'form.logo'(newValue){
      if(this.circle == null){
        if(newValue != null){
          const reader = new FileReader()
          reader.readAsDataURL(newValue)
          reader.onload = () => {
            this.preview = reader.result
          }
        }
        else {
          this.preview = null
        }
      }
    }
  },
  methods: {
    keywordValidator(keyword){
      // no upper case letters except backslash before
      return !/(?<!\\)[A-Z]/g.test(keyword)
    },
    showCircle(circle){
      const colors = {
        colorText: circle?.colorText || '#000000',
        colorBackground: circle?.colorBackground || '#ffffff',
      }
      this.colorPickerText = { hex: colors.colorText }
      this.colorPickerBackground = { hex: colors.colorBackground }
      this.form = {
        ...this.form,
        ...colors,
        logo: null,
      }
      if(this.entity != null && circle != null){
        this.preview = CircleService.getLogoUrl(this.$baseUrl, circle.id)
      }
      else{
        this.preview = null
      }
      this.circle = circle
    },
    onClickCircleNew(){
      this.showCircle(null)
    },
    onClickCircleCancel(){
      this.showCircle(this.circles[this.circleIndex])
    }
  }
}
</script>

<style scoped>
::v-deep .vc-chrome-alpha-wrap{
  display: none;
}
</style>