<template>
  <div id="app">
    <template v-if="auth != null">
      <LoginView
        v-if="!auth"
        v-on:success="auth = true" />
      <router-view v-else />
    </template>
  </div>
</template>

<script>
  import AccessTokenPlugin from '@/plugins/access-token'
  
  import LoginView from '@/views/login'

  export default {
    components: {
      'LoginView': LoginView,
    },
    async created(){
      this.auth = await AccessTokenPlugin.launch()
      this.$root.$on('of:logout', this.onLogout)
    },
    beforeDestroy() {
      this.$root.$off('of:logout', this.onLogout)
    },
    data(){
      return {
        auth: null
      }
    },
    methods: {
      onLogout(){
        this.auth = false
        AccessTokenPlugin.save(null)
      }
    }
  }
</script>

