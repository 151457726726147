<template>
  <div id="Login">
    <vue-particles
      class="particles"
      color="#7d7d7d" />
    <form
      novalidate
      v-on:submit.prevent="onSubmitForm">
      <div class="text-center">
        <h1 class="brand mb-0">ownfin</h1>
        <h5>Administration Tool</h5>
      </div>
      <div class="py-3">
        <div class="form-group">
          <label>Username</label>
          <input
            type="text" class="form-control"
            v-model="form.username"
            :class="{'is-invalid': error.username != null}" />
          <div class="invalid-feedback">
            {{error.username}}
          </div>
        </div>
        <div class="form-group">
          <label>Password</label>
          <input
            type="password" class="form-control"
            v-model="form.password"
            :class="{'is-invalid': error.password != null}" />
          <div class="invalid-feedback">
            {{error.password}}
          </div>
        </div>
      </div>
      <div
        class="alert alert-danger mb-4"
        v-if="error.authentication != null">
        {{error.authentication}}
      </div>
      <div class="clearfix">
        <div class="float-left">
          <button
            type="submit"
            class="btn btn-primary">
            Login
          </button>
        </div>
        <div class="float-right">
          <div class="mt-2">
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
              Forgot password?
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AccessTokenPlugin from '@/plugins/access-token'
import MemberService from '@/services/member'

export default {
  data(){
    return {
      form: {
        username: '',
        password: '',
      },
      error: {},
    }
  },
  methods: {
    async onSubmitForm(){
      this.error = {}
      try{
        const result = await MemberService.login(
          this.form.username,
          this.form.password
        )
        AccessTokenPlugin.save(result.token)
        this.$emit('success')
      }
      catch(ex){
        if(ex.error === true){
          this.error = this.$handleErrors(ex)
        }
      }
    }
  }
}
</script>

<style>
#Login {
  
}

  #Login .particles{
    height: 100vh;
    width: 100vw;
    background: #000000;
  }

  #Login form{
    position: fixed;
    background: #000000;
    color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid #7d7d7d;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
    max-width: 100%;
  }
</style>