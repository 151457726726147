<template>
  <div id="VendorEdit" class="container">
    <div class="clearfix">
      <div class="float-left">
        <h3>Edit vendor</h3>
      </div>
      <div class="float-right">
        <button
          class="btn btn-primary"
          @click="onSubmitForm">
          Save
        </button>
      </div>
    </div>
    <hr />
    <vendor-form
      ref="form"
      v-if="vendor != null"
      :entity="vendor" />
  </div>
</template>

<script>
import VendorService from '@/services/vendor'

import VendorFormComponent from '@/components/vendor-form'

export default {
  name: 'VendorEdit',
  created(){
    this.loadData()
  },
  components: {
    'vendor-form': VendorFormComponent,
  },
  data(){
    return {
      vendor: null
    }
  },
  computed: {
    vendorID(){
      return this.$route.params.id
    }
  },
  methods: {
    async loadData(){
      this.vendor = await VendorService.getByKey(this.vendorID, {
        '_include': [
          'circles',
          'vendorKeywords',
        ]
      })
    },
    async onSubmitForm(){
      const form = this.$refs.form.form
      await VendorService.edit({
        ...form,
        id: this.vendor.id,
      })
      this.$router.push({ name: 'vendor-index' })
    }
  }
}
</script>
