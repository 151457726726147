// @ts-nocheck
import GridComponent from './grid.vue'
import GridControlComponent from './grid-control.vue'
import ErrorAlertComponent from './error-alert.vue'

export default class bdevGrid {
  static options = null
  static accessToken = null

  static install(
    Vue: any,
    options: any
  ){

    bdevGrid.options = options

    Vue.component('grid', {
      ...GridComponent,
      computed: {
        options(){
          return {
            ...bdevGrid.options,
            accessToken: bdevGrid.accessToken,
          }
        },
        ...GridComponent.computed
      }
    })
    Vue.component('grid-control', GridControlComponent)
    Vue.component('error-alert', ErrorAlertComponent)

  }
}