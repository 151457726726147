<template>
  <div id="MemberAdd" class="container">
    <div class="clearfix">
      <div class="float-left">
        <h3>Add member</h3>
      </div>
      <div class="float-right">
        <button
          class="btn btn-primary"
          @click="onSubmitForm">
          Save
        </button>
      </div>
    </div>
    <hr />
    <member-form ref="form" />
  </div>
</template>

<script>
  import MemberService from '@/services/member'

  import MemberFormComponent from '@/components/member-form'

  export default {
    components: {
      'member-form': MemberFormComponent,
    },
    methods: {
      async onSubmitForm(){
        const form = this.$refs.form.form
        form.username = this.$refs.form.username
        form.email = this.$refs.form.email
        await MemberService.create(form)
        this.$router.push({ name: 'member-index' })
      }
    }
  }
</script>