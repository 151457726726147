













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ErrorAlert extends Vue {
  
  @Prop()
  public type?: string
  @Prop()
  public title!: string
  @Prop()
  public errors!: string[]

  get alertType() {
    return 'alert alert-' + (this.type || 'danger')
  }

}
