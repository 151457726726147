<template>
  <form v-on:submit.prevent="$emit('submit')">
    <div class="form-group">
      <label>Entscheidung</label><br />
      <div class="custom-control custom-radio">
        <input type="radio" id="grid-filter-bool-yes" class="custom-control-input"
               :value="true" v-model="value">
        <label class="custom-control-label" for="grid-filter-bool-yes">Ja</label>
      </div>
      <div class="custom-control custom-radio">
        <input type="radio" id="grid-filter-bool-no" class="custom-control-input"
               :value="false" v-model="value">
        <label class="custom-control-label" for="grid-filter-bool-no">Nein</label>
      </div>
    </div>
  </form>
</template>
<script>
  export default {
    data() {
      return {
        value: null
      }
    }
  }
</script>
