<template>
  <b-navbar class="navbar" toggleable="lg" type="dark">
    <b-navbar-brand class="brand" to="/">ownfin</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/json">JSON</b-nav-item>
        <b-nav-item to="/member">Members</b-nav-item>
        <b-nav-item to="/promo">Promo</b-nav-item>
        <b-nav-item to="/category">Category</b-nav-item>
        <b-nav-item to="/vendor">Vendors</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <form method="POST" :action="hangfireRedirect">
          <input type="hidden" name="token" :value="accessToken" />
          <button type="submit" class="nav-link hangfire-button">Hangfire</button>
        </form>
        <b-nav-item v-on:click="$root.$emit('of:logout')">Logout</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import AccessTokenPlugin from '@/plugins/access-token'
import Member_API from '@/services/member'

export default {
  data(){
    return {
      accessToken: AccessTokenPlugin.get(),
      hangfireRedirect: Member_API.getHangfireUrl(),
    }
  }
}
</script>

<style>
  .navbar{
    background: #000000;
    z-index: 100;
  }
  .hangfire-button{
    background: transparent;
    border: none;
  }
</style>