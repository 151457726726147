<template>
  <th class="user-select-none align-middle"
      v-if="parentGrid">
    <table class="table-plain w-100">
      <tr>
        <td>
          <span class="text-nowrap"
                v-bind:class="{'cursor-pointer': !disableSort}"
                v-on:click="emitSort">
            <slot></slot>
            &nbsp;
            <span class="text-secondary"
                  v-if="!disableSort">
              <span v-if="sortDirection === null">
                <fa-icon icon="sort"></fa-icon>
              </span>
              <span class="text-info" v-else>
                <fa-icon icon="chevron-up" v-if="sortDirection === true"></fa-icon>
                <fa-icon icon="chevron-down" v-else-if="sortDirection === false"></fa-icon>

              </span>
              <span v-if="showMultiOrder"
                    class="badge badge-pill badge-info ml-1 align-top"
                    style="margin-top: 3px;">{{orderIndex + 1}}</span>
            </span>
          </span>
        </td>
        <td class="text-right align-middle">
          <grid-filter class="ml-2"
                       v-if="!disableFilter"
                       v-bind="$options.propsData"
                       v-on:add="parentGrid.$emit('grid-add-filter', $event)"><h1>Test</h1></grid-filter>
        </td>
      </tr>
    </table>
    <div class="cursor-pointer"
         v-for="filter in controlFilters"
         v-bind:key="`${filter.key}-${filter.index}`"
         v-on:click="parentGrid.$emit('grid-remove-filter', filter)">
      <span class="badge badge-pill badge-primary" style="font-size: 90%">
        <span v-if="type == 'text'">{{filter.operator}} '{{filter.query}}'</span>
        <span v-if="type == 'number'">{{filter.operator}} {{filter.query}}</span>
        <span v-if="type == 'date'">{{filter.operator}} {{filter.query | date}}</span>
        <span v-if="type == 'bool'">{{filter.operator}} {{filter.query | bool}}</span>
      </span>
    </div>
  </th>
</template>

<script>
  import GridFilterComponent from './grid-filter'

  export default {
    components: {
      'grid-filter': GridFilterComponent
    },
    created() {
      var grid = this.$parent
      while (grid && grid.$options._componentTag != 'grid') {
        grid = grid.$parent
      }
      if (grid) this.parentGrid = grid
    },
    props: ['property', 'type'],
    inject: ['orders', 'filters', 'keypressed'],
    data() {
      return {
        parentGrid: null
      }
    },
    computed: {
      disableSort() {
        var attr = this.$attrs['disable-sort']
        return attr === '' || attr === true || this.property == null
      },
      disableFilter() {
        var attr = this.$attrs['disable-filter']
        return attr === '' || attr === true || this.property == null
      },
      controlFilters() {
        var filters = []
        Object.keys(this.filters.obj).forEach(key => {
          var queries = this.filters.obj[key]
          var keyParts = key.split('|')
          var property = keyParts[0]
          if (property == this.property &&
            queries !== undefined &&
            queries !== null &&
            Array.isArray(queries)) {
            var operator = keyParts[1]
            queries.forEach((query, index) => {
              filters.push({
                key,
                index,
                query,
                operator
              })
            })
          }
        })
        return filters
      },
      showMultiOrder() {
        return this.orderIndex >= 0 && (
          this.keypressed.ctrl ||
          this.orders.list.length >= 2)
      },
      orderIndex() {
        return this.orders.list.map(order => {
          return order.toLowerCase().split(' ')[0] == this.property.toLowerCase()
        }).indexOf(true)
      },
      sortDirection() {
        if (this.orderIndex >= 0) {
          var orderString = this.orders.list[this.orderIndex]
          if (orderString.endsWith('asc')) return true
          if (orderString.endsWith('desc')) return false
        }
        return null
      }
    },
    methods: {
      emitSort() {
        if (!this.disableSort)
          this.parentGrid.$emit('grid-sort', this.property, this.orderIndex)
      }
    }
  }
</script>

<style scoped>
  .table-plain tr,
  .table-plain tr:hover,
  .table-plain td {
    padding: 0px;
    background-color: transparent;
    border: none;
  }
</style>