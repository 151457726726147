<template>
  <div id="PromoAdd" class="container">
    <div class="clearfix">
      <div class="float-left">
        <h3>Add promo</h3>
      </div>
      <div class="float-right">
        <button
          class="btn btn-primary"
          @click="onSubmitForm">
          Save
        </button>
      </div>
    </div>
    <hr />
    <promo-form ref="form" />
  </div>
</template>

<script>
  import PremiumService from '@/services/premium'

  import PromoFormComponent from '@/components/promo-form'

  export default {
    components: {
      'promo-form': PromoFormComponent,
    },
    methods: {
      async onSubmitForm(){
        const form = this.$refs.form.form
        await PremiumService.createPromo(form)
        this.$router.push({ name: 'promo-index' })
      },
    }
  }
</script>