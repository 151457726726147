import _API from '../_.js'

export default new class Static_Status_API extends _API {

  constructor(){
    super('static/Status', 1)
  }

  create(entity){
    return super.post(null, entity)
  }

  edit(entity){
    return super.put(null, entity)
  }

}