import _API from './_.js'

export default new class Category_API extends _API {

  constructor(){
    super('Category', 1)
  }

  create(entity){
    return super.postForm(null, entity)
  }

  edit(entity){
    return super.putForm(null, entity)
  }

}