import bdevGrid from 'bastiandev-grid'
import { ApiService } from 'bastiandev-util/api-service'

import MemberService from '@/services/member'

export default new class AccessTokenPlugin{
  localStorageKey = 'of:access-token'

  get(){
    return localStorage.getItem(this.localStorageKey)
  }
  async launch(){
    const accessToken = this.get()
    this._set(accessToken)
    try{
      await MemberService.me()
      return true
    }
    catch{
      this.save(null)
      return false
    }
  }
  save(accessToken){
    localStorage.setItem(this.localStorageKey, accessToken)
    this._set(accessToken)
  }

  _set(accessToken){
    bdevGrid.accessToken = accessToken
    ApiService.accessToken = accessToken
  }
}