<template>
  <div id="JsonIndex" class="container">
    <div class="clearfix">
      <div class="float-left">
        <h3>Static JSON Export</h3>
      </div>
      <div class="float-right">
        <form @submit.prevent="onSubmitForm">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Type"
              v-model="form.type" />
            <div class="input-group-append">
              <button
                type="submit"
                class="btn btn-primary">
                <fa-icon icon="plus" /> Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr />
    <grid
      ref="grid"
      section="/v1/static/" schema="Status" action=""
      default-order="type asc"
      disable-tbody>
      <template v-slot:thead>
        <tr>
          <grid-control />
          <grid-control
            property="type"
            type="text">
            Type
          </grid-control>
          <grid-control
            property="version"
            type="number">
            Version
          </grid-control>
          <grid-control />
        </tr>
      </template>
      <template v-slot:tbody="data">
        <json-export
          v-for="row in data.rows"
          :key="`static-status_${row.id}`"
          :entity="row" />
      </template>
    </grid>
  </div>
</template>

<script>
import StaticStatusService from '@/services/static/status'

import JsonExportComponent from '@/components/json-export'

function blankForm(){
  return {
    type: ''
  }
}

export default {
  components: {
    'json-export': JsonExportComponent
  },
  data(){
    return {
      form: blankForm(),
    }
  },
  methods: {
    async onSubmitForm(){
      await StaticStatusService.create(this.form)
      this.form = blankForm()
      this.$refs.grid.updateGrid()
    },
  }
}
</script>