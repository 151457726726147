<template>
  <div id="CategoryAdd" class="container">
    <div class="clearfix">
      <div class="float-left">
        <h3>Add category</h3>
      </div>
      <div class="float-right">
        <button
          class="btn btn-primary"
          @click="onSubmitForm">
          Save
        </button>
      </div>
    </div>
    <hr />
    <category-form ref="form" />
  </div>
</template>

<script>
import CategoryService from '@/services/category'

import CategoryFormComponent from '@/components/category-form'

export default {
  name: 'CategoryAdd',
  components: {
    'category-form': CategoryFormComponent,
  },
  methods: {
    async onSubmitForm(){
      const form = this.$refs.form.form
      await CategoryService.create(form)
      this.$router.push({ name: 'category-index' })
    }
  }
}
</script>
