<template>
  <div id="CategoryEdit" class="container">
    <div class="clearfix">
      <div class="float-left">
        <h3>Edit category</h3>
      </div>
      <div class="float-right">
        <button
          class="btn btn-primary"
          @click="onSubmitForm">
          Save
        </button>
      </div>
    </div>
    <hr />
    <category-form
      ref="form"
      v-if="category != null"
      :entity="category" />
  </div>
</template>

<script>
import CategoryService from '@/services/category'

import CategoryFormComponent from '@/components/category-form'

export default {
  name: 'CategoryEdit',
  created(){
    this.loadData()
  },
  components: {
    'category-form': CategoryFormComponent,
  },
  data(){
    return {
      category: null
    }
  },
  computed: {
    categoryID(){
      return this.$route.params.id
    }
  },
  methods: {
    async loadData(){
      this.category = await CategoryService.getByKey(this.categoryID, {
        '_include': [
          'categoryKeywords',
        ]
      })
    },
    async onSubmitForm(){
      const form = this.$refs.form.form
      await CategoryService.edit({
        ...form,
        id: this.category.id,
      })
      this.$router.push({ name: 'category-index' })
    }
  }
}
</script>
