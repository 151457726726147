import Vue from 'vue'
import VueRouter from 'vue-router'

import MainLayout from '@/layouts/main'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: 'index',
        component: require('@/views/index').default,
        meta: {
          layout: MainLayout
        }
      },
      {
        path: '/json',
        name: 'json-index',
        component: require('@/views/json/index').default
      },
      {
        path: '/member',
        name: 'member-index',
        component: require('@/views/member/index').default
      },
      {
        path: '/member/add',
        name: 'member-add',
        component: require('@/views/member/add').default
      },
      {
        path: '/promo',
        name: 'promo-index',
        component: require('@/views/promo/index').default
      },
      {
        path: '/promo/add',
        name: 'promo-add',
        component: require('@/views/promo/add').default
      },
      {
        path: '/category',
        name: 'category-index',
        component: require('@/views/category/index').default,
      },
      {
        path: '/category/add',
        name: 'category-add',
        component: require('@/views/category/add').default,
      },
      {
        path: '/category/edit/:id',
        name: 'category-edit',
        component: require('@/views/category/edit').default,
      },
      {
        path: '/vendor',
        name: 'vendor-index',
        component: require('@/views/vendor/index').default,
      },
      {
        path: '/vendor/add',
        name: 'vendor-add',
        component: require('@/views/vendor/add').default,
      },
      {
        path: '/vendor/edit/:id',
        name: 'vendor-edit',
        component: require('@/views/vendor/edit').default,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})

export default router
