<template>
  <div class="row">
    <div class="col-6">
      <form @submit.prevent>
        <div class="form-group">
          <label>Name</label>
          <input
            type="text"
            class="form-control"
            v-model="form.name" />
        </div>
        <div class="form-group">
          <label>Identifier</label>
          <input
            type="text"
            class="form-control"
            :disabled="entity != null"
            v-model="form.identifier" />
        </div>
        <div class="form-group">
          <label>Keywords (Regex)</label>
          <BFormTags
            input-id="tags-pills"
            v-model="form.keywords"
            tag-variant="primary"
            tag-pills
            size="lg"
            placeholder="Enter keyword and press enter"
            :tag-validator="keywordValidator" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Chrome as ColorPicker } from 'vue-color'

export default {
  components: {
    'ColorPicker': ColorPicker,
  },
  props: ['entity'],
  data(){
    return {
      form: {
        name: this.entity?.name || '',
        identifier: this.entity?.identifier || '',
        keywords: this.entity?.categoryKeywords.map(categoryKeyword => {
          return categoryKeyword.value
        }) || [],
      },
    }
  },
  watch: {
    'form.name'(newValue){
      if(this.entity == null){
        this.form.identifier = newValue
          .trim()
          .toLowerCase()
          .replace(/ä/g, 'ae')
          .replace(/ö/g, 'oe')
          .replace(/ü/g, 'ue')
          .replace(/\W+/g, '_')
      }
    },
  },
  methods: {
    keywordValidator(keyword){
      // no upper case letters except backslash before
      return !/(?<!\\)[A-Z]/g.test(keyword)
    },
  }
}
</script>
