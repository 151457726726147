<template>
  <tbody>
    <tr>
      <td class="cell-fit">
        <div
          class="cursor-pointer"
          @click="open = !open">
          <fa-icon icon="minus" v-if="open" />
          <fa-icon icon="plus" v-else />
        </div>
      </td>
      <td>{{entity.type}}</td>
      <td>
        <button
          class="btn btn-danger btn-sm btn-cell"
          :disabled="state.editing || entity.version == 1"
          @click="onClickVersionDown">
          <fa-icon icon="minus" />
        </button>
        <span class="px-3">{{entity.version}}</span>
        <button
          class="btn btn-success btn-sm btn-cell"
          :disabled="state.editing"
          @click="onClickVersionUp">
          <fa-icon icon="plus" />
        </button>
      </td>
      <td class="cell-fit">
        <button
          class="btn btn-primary btn-sm btn-cell mr-1"
          :disabled="state.loading"
          @click="onClickReload">
          <fa-icon icon="redo" />
        </button>
        <button
          class="btn btn-primary btn-sm btn-cell"
          :disabled="state.loading"
          @click="onClickClipboard">
          <fa-icon icon="clipboard" />
        </button>
      </td>
    </tr>
    <tr>
      <td
        colspan="4"
        v-if="open">
        <div class="row">
          <div class="col-12">
            <pre class="json-generator-display">{{display}}</pre>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import _API from '@/services/_'
import StaticStatusService from '@/services/static/status'

export default {
  props: ['entity'],
  created(){
    this.service = new _API(this.entity.type, 1)
    this.getData()
  },
  data() {
    return {
      service: null,
      open: false,
      entities: null,
      timestamp: null,
      state: {
        loading: true,
        editing: false,
      }
    }
  },
  computed: {
    fileData(){
      return {
        type: this.entity.type,
        version: this.entity.version,
        timestamp: this.timestamp,
        entities: this.entities,
      }
    },
    display() {
      return JSON.stringify(this.fileData, null, 2)
    }
  },
  methods: {
    async getData(){
      this.state.loading = true
      try{
        this.timestamp = null
        this.entities = null
        const liveData = await this.service.get('Static')
        this.entities = liveData.entities.map(entity => {
          delete entity.deleted
          return entity
        })
        this.timestamp = liveData.timestamp
        this.state.loading = false
      }
      catch(ex) { console.error(ex) }
    },
    async incrementVersion(steps){
      this.state.editing = true
      const entity = JSON.parse(JSON.stringify(this.entity))
      entity.version += steps
      try{
        await StaticStatusService.edit(entity)
        this.entity.version = entity.version
      }
      catch(ex) { console.error(ex) }
      this.state.editing = false
    },
    onClickVersionDown(){
      this.incrementVersion(-1)
    },
    onClickVersionUp(){
      this.incrementVersion(+1)
    },
    onClickClipboard(){
      const json = JSON.stringify(this.fileData)
      navigator.clipboard.writeText(json)
    },
    onClickReload(){
      this.getData()
    }
  }
}
</script>

<style>
.json-generator-display{
  height: 300px;
  min-height: 100px;
  padding: 10px;
  border-radius: 0.25rem;
  background: #d2d2d2;
  overflow-y: scroll;
  overflow-x: auto;
  resize: vertical;
}
</style>