import _API from './_.js'

export default new class Circle_API extends _API {

  constructor(){
    super('Circle', 1)
  }

  getLogoUrl(baseUrl, circleID){
    const url = `${baseUrl}/v1/Circle/${circleID}/Image/?size=300&output=png`
    return url
  }

}