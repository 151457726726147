import _API from './_.js'

export default new class Premium_API extends _API {

  constructor(){
    super('Premium', 1)
  }

  createPromo(entity){
    return super.post('Promo', entity)
  }

}