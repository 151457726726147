<template>
  <form v-on:submit.prevent="$emit('submit')">
    <div class="form-group">
      <label>Text</label>
      <input type="text" step="any" class="form-control"
             v-model="value" />
    </div>
  </form>
</template>
<script>
  export default {
    data() {
      return {
        value: null
      }
    }
  }
</script>
