import _API from './_.js'

export default new class Vendor_API extends _API {

  constructor(){
    super('Vendor', 1)
  }

  create(entity){
    return super.postForm(null, entity)
  }

  edit(entity){
    return super.putForm(null, entity)
  }

  getLogoUrl(baseUrl, vendorID, version){
    const url = `${baseUrl}/v1/Vendor/${vendorID}/Logo/?version=${version}&size=300&output=png`
    return url
  }

}