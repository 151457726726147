<template>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>First name</label>
        <input
          type="text"
          class="form-control"
          v-model="form.firstName" />
      </div>
      <div class="form-group">
        <label>Last name</label>
        <input
          type="text"
          class="form-control"
          v-model="form.lastName" />
      </div>
      <div class="form-group">
        <label>Username</label>
        <input
          type="text"
          class="form-control"
          disabled
          v-bind:value="username" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input
          type="text"
          class="form-control"
          disabled
          v-bind:value="email" />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input
          type="text"
          class="form-control"
          v-model="form.password" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        form: {
          firstName: '',
          lastName: '',
          password: '',
        }
      }
    },
    computed: {
      username(){
        return `${this.form.firstName.trim()}.${this.form.lastName.trim()}`
          .toLowerCase()
          .replace(/ +/g, '-')
          .replace(/ä/g, 'ae')
          .replace(/ö/g, 'oe')
          .replace(/ü/g, 'ue')
      },
      email(){
        return `${this.username}@ownfin.com`
      }
    }
  }
</script>