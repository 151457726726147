import _API from './_.js'

export default new class Member_API extends _API {

  constructor(){
    super('Member', 1)
  }

  create(entity){
    return super.post(null, entity)
  }

  login(username, password){
    return super.post('Login', {
      username, password
    })
  }

  me(){
    return super.get('Me')
  }

  getHangfireUrl(){
    return `${this.url}/Hangfire`
  }

}