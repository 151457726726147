<template>
  <div id="VendorAdd" class="container">
    <div class="clearfix">
      <div class="float-left">
        <h3>Add vendor</h3>
      </div>
      <div class="float-right">
        <button
          class="btn btn-primary"
          @click="onSubmitForm">
          Save
        </button>
      </div>
    </div>
    <hr />
    <vendor-form ref="form" />
  </div>
</template>

<script>
import VendorService from '@/services/vendor'

import VendorFormComponent from '@/components/vendor-form'

export default {
  name: 'VendorAdd',
  components: {
    'vendor-form': VendorFormComponent,
  },
  methods: {
    async onSubmitForm(){
      const form = this.$refs.form.form
      await VendorService.create(form)
      this.$router.push({ name: 'vendor-index' })
    }
  }
}
</script>
