<template>

  <div class="input-group input-group-sm">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary"
              v-bind:disabled="disableLeft"
              v-on:click="pageFirst()">
        <fa-icon icon="angle-double-left"></fa-icon>
      </button>
      <button class="btn btn-outline-secondary"
              v-bind:disabled="disableLeft"
              v-on:click="pagePrev()">
        <fa-icon icon="angle-left"></fa-icon>
      </button>
    </div>

    <input type="text" style="width: 65px" class="form-control px-2"
           v-bind:value="page"
           v-on:keypress.enter="onSubmit" />

    <div class="input-group-append">
      <div class="input-group-text">von {{pagecount}}</div>
      <button class="btn btn-outline-secondary"
              v-bind:disabled="disableRight"
              v-on:click="pageNext()">
        <fa-icon icon="angle-right"></fa-icon>
      </button>
      <button class="btn btn-outline-secondary"
              v-bind:disabled="disableRight"
              v-on:click="pageLast()">
        <fa-icon icon="angle-double-right"></fa-icon>
      </button>
    </div>
  </div>

</template>

<script>
  export default {
    props: ['pagecount', 'value'],
    data() {
      return {
        page: 1
      }
    },
    computed: {
      disableLeft() {
        return this.page <= 1
      },
      disableRight() {
        return this.pagecount <= 0 || this.page >= this.pagecount
      }
    },
    watch: {
      value() {
        this.page = this.value
      }
    },
    methods: {
      pageFirst(silent) {
        this.page = 1
        if (!silent) this.emit()
      },
      pagePrev() {
        this.page--
        this.emit()
      },
      pageNext() {
        this.page++
        this.emit()
      },
      pageLast(silent) {
        this.page = this.pagecount
        if (!silent) this.emit()
      },
      emit() {
        if (this.page < 1) {
          this.pageFirst(true)
        }
        if (this.page > this.pagecount) {
          this.pageLast(true)
        }
        this.$emit('input', this.page)
      },
      onSubmit(event) {
        var query = parseInt(event.target.value)
        if (isNaN(query)) return
        this.page = query
        this.emit()
      }
    }
  }
</script>
