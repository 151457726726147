<template>
  <table class="table table-bordered"
         v-bind:class="{'grid-loading': loading && this.data}"
         v-if="errors.length == 0">
    <thead class="small">
      <slot name="thead"></slot>
    </thead>

    <template v-if="data && data.rows.length > 0">
      <tbody v-if="!disableTbody">
        <slot name="tbody" v-bind="data" />
      </tbody>
      <slot name="tbody" v-bind="data" v-else />
    </template>
    <tbody v-else>
      <tr v-if="data">
        <td v-bind:colspan="colspanValue">
          <h4 class="mb-0">Keine Ergebnisse vorhanden.</h4>
        </td>
      </tr>
      <tr v-else>
        <td class="text-center"
            v-bind:colspan="colspanValue">
          <span class="spinner-border text-primary"></span>
        </td>
      </tr>
    </tbody>
    <tfoot v-if="!disablePagination">
      <tr>
        <td v-bind:colspan="colspanValue">
          <div class="float-left">
            <grid-pagination v-model="page"
                             v-bind:pagecount="pagecount"
                             v-bind:pagesize="pagesize"
                             v-on:input="updateGrid()"></grid-pagination>
          </div>
          <div class="float-right">
            <div class="d-inline-block">
              <div class="input-group input-group-sm">

                <fragment v-if="$scopedSlots.legend != null">
                  <button class="btn btn-outline-secondary btn-sm mr-2 grid-footer-button"
                          v-bind:id="legendKey">
                    <fa-icon icon="info" style="pointer-events: none" />
                  </button>
                  <b-popover triggers="hover" placement="top"
                             v-bind:target="legendKey">
                    <template v-slot:title>
                      Legende
                    </template>
                    <slot name="legend" />
                  </b-popover>
                </fragment>
                <fragment v-if="name">
                  <button class="btn btn-outline-secondary btn-sm mr-2 grid-footer-button"
                          v-on:click="onButtonHyperlink">
                    <fa-icon icon="link" style="pointer-events: none" />
                  </button>
                </fragment>

                <select class="form-control rounded-left"
                        v-model="pagesize"
                        v-on:change="updateGrid(true)">
                  <option v-for="paginationUnit in paginationValue"
                          v-bind:key="paginationUnit">
                    {{paginationUnit}}
                  </option>
                </select>
                <div class="input-group-append">
                  <div class="input-group-text">von {{count}}</div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
  <error-alert v-else
               type="secondary"
               title="Fehler bei der Konfiguration des Grids"
               v-bind:errors="errors"></error-alert>
</template>

<script>
  import GridPaginationComponent from './grid-pagination'
  import qs from 'qs'

  export default {
    components: {
      'grid-pagination': GridPaginationComponent,
    },
    props: ['name', 'section', 'schema', 'action', 'include',
            'pagination', 'property', 'order', 'colspan',
            'default-order', 'default-pagesize', 'default-filters',
            'background-filters',
            'sum-all', 'sum-page', 'avg-all', 'avg-page', 'min-all', 'min-page', 'max-all', 'max-page'],
    created() {
      window.addEventListener('keydown', this.onKeyDown)
      window.addEventListener('keyup', this.onKeyUp)

      var hasHyperLink = this.checkHyperlink()

      if (!hasHyperLink) {
        this.setFilterObj(this.defaultFilters)

        if (this.order) {
          this.setOrderList(this.order)
        }
        else if (this.defaultOrder) {
          var defaultOrder = this.setOrderList(this.defaultOrder)
          this.$emit('update:order', defaultOrder)
        }
      }

      if (this.disablePagination) {
        this.pagesize = null
      }
      else if (this.defaultPagesize) {
        var defaultPagesize = parseInt(this.defaultPagesize)
        if (!isNaN(defaultPagesize)) {
          this.pagesize = defaultPagesize
        }
      }
      else if(this.paginationValue && Array.isArray(this.paginationValue)) {
        this.pagesize = this.paginationValue[0]
      }

      this.updateGrid()
    },
    mounted() {
      this.verifyGrid()
      this.$on('grid-sort', this.onSort)
      this.$on('grid-add-filter', this.onAddFilter)
      this.$on('grid-remove-filter', this.onRemoveFilter)

      const gridControls = this.$children.filter(child => {
        return child.$options._componentTag == 'grid-control'
      })
      this.colspanCalculated = gridControls.reduce((sum, gridControl) => sum + (parseInt(gridControl.$attrs['colspan']) || 1), 0)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.onKeyDown)
      window.removeEventListener('keyup', this.onKeyUp)
    },
    data() {
      return {
        uuid: this.$uuid.v4(),
        data: null,
        request: 0,
        count: 0,
        page: 1,
        pagesize: 1,
        orders: { list: [] },
        filters: { obj: {} },
        colspanCalculated: 0,
        loading: true,
        errors: [],
        keypressed: {
          ctrl: false
        }
      }
    },
    provide() {
      return {
        orders: this.orders,
        filters: this.filters,
        keypressed: this.keypressed
      }
    },
    computed: {
      paginationValue() {
        let pagination = [15, 30, 50, 100]
        if (this.pagination) pagination = this.pagination
        var defaultPagesize = parseInt(this.defaultPagesize)
        if (!isNaN(defaultPagesize)) {
          if(pagination.indexOf(defaultPagesize) == -1){
            pagination.push(defaultPagesize)
          }
        }
        return pagination.sort((a, b) => a - b)
      },
      pagecount() {
        return Math.ceil(this.count / this.pagesize);
      },
      url() {
        const url = `${this.options.baseUrl}${this.section || '/'}${this.schema}/${this.action}`
        return url
      },
      colspanValue() {
        return this.colspan || this.colspanCalculated
      },
      requestFilters() {
        if (this.backgroundFilters) {
          return { ...this.filters.obj, ...this.backgroundFilters }
        }
        return this.filters.obj
      },
      requestPagination() {
        if (!this.pagesize) return;
        return {
          '_take': this.pagesize,
          '_skip': (this.page - 1) * this.pagesize
        }
      },
      requestAggregates() {
        return {
          '_sumAll': this.sumAll,
          '_sumPage': this.sumPage,
          '_avgAll': this.avgAll,
          '_avgPage': this.avgPage,
          '_minAll': this.minAll,
          '_minPage': this.minPage,
          '_maxAll': this.maxAll,
          '_maxPage': this.maxPage
        }
      },
      disablePagination() {
        var attr = this.$attrs['disable-pagination']
        return attr === '' || attr === true
      },
      disableTbody() {
        var attr = this.$attrs['disable-tbody']
        return attr === '' || attr === true
      },
      hash() {
        const name = this.name
        if (name) {
          var hash = 0, i, chr
          for (i = 0; i < name.length; i++) {
            chr   = name.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0;
          }
          return Math.abs(hash)
        }
        return null
      },
      legendKey() {
        return `grid-legend_${this.uuid}`
      },
      hyperlinkKey() {
        return this.hash ? `grid_${this.hash}` : null
      }
    },
    watch: {
      url(newVal, oldVal) {
        if (oldVal != newVal)
          this.updateGrid(true)
      },
      backgroundFilters(newVal, oldVal) {
        if (JSON.stringify(oldVal) != JSON.stringify(newVal))
          this.updateGrid(true)
      },
      order(newVal) {
        if (JSON.stringify(newVal) != JSON.stringify(this.orders.list)) {
          this.setOrderList(newVal)
          this.updateGrid()
        }
      }
    },
    methods: {
      verifyGrid() {
        var errors = []

        if (this.schema == null) {
          errors.push('Eigenschaft "schema" ist nicht gesetzt.')
        }
        if (this.action == null) {
          errors.push('Eigenschaft "action" ist nicht gesetzt.')
        }
        if (this.$scopedSlots.thead == null) {
          errors.push('Slot "thead" ist nicht gesetzt.')
        }
        if (this.$scopedSlots.tbody == null) {
          errors.push('Slot "tbody" ist nicht gesetzt.')
        }

        this.errors = errors
      },
      async updateGrid(resetPage) {
        if (resetPage) this.page = 1
        this.loading = true
        var request = ++this.request
        const query = {
          '_grid': true,
          '_order': this.orders.list,
          '_include': this.include,
          ...this.requestFilters,
          ...this.requestPagination,
          ...this.requestAggregates
        }
        const queryString = qs.stringify(query, {
          indices: false,
        })
        let headers = {}
        if(this.options.accessToken != null){
          headers['Authorization'] = `Bearer ${this.options.accessToken}`
        }
        var response = await (
          await fetch(`${this.url}?${queryString}`, {
            headers
          })
        ).json()
        var result = response
        if (this.property) {
          this.property.split('.').forEach(property => {
            result = result[property]
          })
        }
        if (request != this.request) return;
        this.data = result
        this.count = result.count
        this.loading = false
        this.$emit('update', {
          gridResult: result,
          responseData: response
        })
      },
      onSort(property, orderIndex) {
        var nextAsc = true
        if (orderIndex >= 0 && (this.keypressed.ctrl || this.orders.list.length == 1)) {
          nextAsc = this.orders.list[orderIndex].endsWith('desc')
        }
        var orderString = `${property} ${nextAsc ? 'asc' : 'desc'}`
        if (this.keypressed.ctrl) {
          if (orderIndex >= 0) {
            this.$set(this.orders.list, orderIndex, orderString)
          }
          else {
            this.orders.list.push(orderString)
          }
        }
        else {
          this.orders.list = [orderString]
        }
        this.$emit('update:order', JSON.parse(JSON.stringify(this.orders.list)))
        this.updateGrid()
      },
      onRemoveFilter(filter) {
        this.$delete(this.filters.obj[filter.key], filter.index)
        if (this.filters.obj[filter.key].length == 0) {
          this.$delete(this.filters.obj, filter.key)
        }
        this.updateGrid(true)
      },
      onAddFilter(filter) {
        var key = `${filter.property}|${filter.operator}`
        if (filter.push) {
          var existingFilters = this.filters.obj[key] || []
          this.$set(this.filters.obj, key, [...existingFilters, filter.query])
        }
        else {
          this.$set(this.filters.obj, key, [filter.query])
        }
        this.updateGrid(true)
      },
      setFilterObj(filters) {
        var mappedFilters = {}
        if (filters) {
          Object.keys(filters).forEach(key => {
            var query = filters[key]
            var newKey = key
            if (newKey.indexOf('|') === -1) {
              newKey += '|='
            }
            mappedFilters[newKey] = Array.isArray(query) ? query : [query]
          })
        }
        this.$set(this.filters, 'obj', mappedFilters)
        return mappedFilters
      },
      setOrderList(orders) {
        var ordersCopy = []
        if (orders) {
          ordersCopy = JSON.parse(JSON.stringify(orders))
        }
        this.$set(this.orders, 'list', Array.isArray(ordersCopy) ? ordersCopy : [ordersCopy])
        return ordersCopy
      },
      onButtonHyperlink(event) {
        var data = {
          f: this.filters.obj,
          o: this.orders.list.length > 0 ?
            this.orders.list : undefined // waiting for bugfix https://github.com/ljharb/qs/issues/310
        }
        var params = qs.stringify(data, {
          arrayFormat: 'comma',
          encode: false
        })
        var base64 = btoa(params)
          .replace(/\+/g, '.')
          .replace(/\//g, '_')
          .replace(/=/g, '-')
        var query = this.$route.query
        query[this.hyperlinkKey] = base64
        var path = this.$router.resolve(
          {
            name: this.$route.name,
            params: this.$route.params,
            query
          }
        ).href
        var url = window.location.origin + path
        copyToClipboard(url)
        this.$bvToast.toast(`Link erfolgreich in den Zwischenspeicher kopiert.`, {
          title: 'Hinweis',
          variant: 'primary',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true
        })
        event.target.focus()

        function copyToClipboard(text) {
          var dummy = document.createElement("textarea");
          dummy.style.opacity = 0
          document.body.appendChild(dummy);
          dummy.value = text;
          dummy.select();
          document.execCommand("copy");
          document.body.removeChild(dummy);
        }
      },
      checkHyperlink() {
        if (this.hyperlinkKey) {
          var urlData = this.$route.query[this.hyperlinkKey]
          if (urlData != null) {
            var base64 = urlData
              .replace(/\./g, '+')
              .replace(/_/g, '/')
              .replace(/-/g, '=')
            var params = atob(base64)
            var data = qs.parse(params, { comma: true })

            this.setFilterObj(data.f)
            this.setOrderList(data.o)
            if (data.o) {
              this.$emit('update:order', data.o)
            }

            return true
          }
        }
        return false
      },
      onKeyDown(event) {
        if (event.keyCode === 17) {
          if(!this.keypressed.ctrl)
            this.keypressed.ctrl = true
        }
      },
      onKeyUp(event) {
        if (event.keyCode === 17) {
          if(this.keypressed.ctrl)
            this.keypressed.ctrl = false
        }
      }
    }
  }
</script>

<style>
  .grid-loading tbody{
    opacity: 0.3
  }

  .grid-footer-button{
    width: 31px;
  }

  .cursor-pointer {
    cursor: pointer;
  }
</style>
