<template>
  <div
    class="circle-display"
    :style="styleCircle">
    <img :src="image" v-if="image != null" />
    <span v-if="text != null">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: ['color-background', 'color-text', 'size-text', 'image', 'text'],
  computed: {
    styleCircle(){
      return {
        'background-color': this.colorBackground,
        'color': this.colorText,
        'fontSize': this.sizeText,
      }
    }
  }
}
</script>

<style>
.circle-display{
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 100%;
  border-radius: 100%;
  border: 2px solid #d2d2d2;
  overflow: hidden;
  white-space: nowrap;
}
  .circle-display img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    height: 65%;
    width: 65%;
    object-fit: contain;
    transform: translate(-50%, -50%);
  }
  .circle-display span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>