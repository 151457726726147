<template>
  <div id="VendorIndex" class="container container-xxl">
    <div class="clearfix">
      <div class="float-left">
        <h3>Vendors</h3>
      </div>
      <div class="float-right">
        <router-link
          class="btn btn-primary"
          to="/vendor/add">
          <fa-icon icon="plus" /> New
        </router-link>
      </div>
    </div>
    <hr />
    <grid
      section="/v1/" schema="Vendor" action="Grid"
      default-order="name asc"
      default-pagesize="7">
      <template v-slot:thead>
        <tr>
          <grid-control rowspan="2">Circle</grid-control>
          <grid-control
            rowspan="2"
            property="id"
            type="text">ID</grid-control>
          <grid-control
            rowspan="2"
            property="identifier"
            type="text">Identifier</grid-control>
          <grid-control
            rowspan="2"
            property="name"
            type="text">Name</grid-control>
          <th colspan="2">Color</th>
          <grid-control rowspan="2" />
        </tr>
        <tr>
          <grid-control
            property="circleColorBackground"
            type="text">BG</grid-control>
          <grid-control
            property="circleColorText"
            type="text">Text</grid-control>
        </tr>
      </template>
      <template v-slot:tbody="data">
        <tr
          v-for="row in data.rows"
          v-bind:key="`vendor_${row.id}`">
          <td class="cell-fit text-center">
            <div class="circle-container mr-2">
              <CircleDisplay
                :color-background="row.circleColorBackground"
                :image="getLogoUrl(row)" />
            </div>
            <div class="circle-container" >
              <CircleDisplay
                :color-background="row.circleColorBackground"
                :color-text="row.circleColorText"
                :text="row.fallback"
                size-text="14pt" />
            </div>
          </td>
          <td>{{row.id}}</td>
          <td>{{row.identifier}}</td>
          <td>{{row.name}}</td>
          <td>{{row.circleColorBackground}}</td>
          <td>{{row.circleColorText}}</td>
          <td class="cell-fit">
            <RouterLink
              class="btn btn-sm btn-primary btn-cell"
              :to="{
                name: 'vendor-edit',
                params: { id: row.id }
              }">
              <fa-icon icon="edit" />
            </RouterLink>
          </td>
        </tr>
      </template>
    </grid>

  </div>
</template>

<script>
import CircleService from '@/services/circle'

import CircleDisplayComponent from '@/components/circle-display'

export default {
  name: 'VendorIndex',
  components: {
    'CircleDisplay': CircleDisplayComponent,
  },
  methods: {
    getLogoUrl(vendor){
      return CircleService.getLogoUrl(this.$baseUrl, vendor.circleID)
    }
  }
}
</script>

<style scoped>
.circle-container{
  display: inline-block;
  width: 100px;
}
</style>