function check(response) {
  const result = { count: 0 }
  if (response.error) {
    Object.keys(response).forEach(key => {
      const value = response[key]
      if (value.code) {
        const keyParts = key.split('.')
        const lastKeyPart = keyParts[keyParts.length - 1]
        result[lastKeyPart] = value.message
        result.count++
      }
    })
  }
  return result
}

export default class ErrorHandler {
  static install(Vue){

    Vue.mixin({
      methods: {
        $handleErrors(response) {
          return check(response)
        },
      }
    })

  }
}
