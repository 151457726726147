<template>
  <div v-if="setting">
    <span class="text-secondary cursor-pointer">
      <fa-icon icon="filter"
               v-bind:id="`popover-${id}`"></fa-icon>
    </span>
    <b-popover placement="bottom"
               title="Filter"
               triggers="click"
               v-bind:target="`popover-${id}`"
               v-bind:show.sync="show"
               v-on:show="$root.$emit('bv::hide::popover')"
               v-on:hide="resetOperator">
      <div class="form-group" v-if="hasOperators(2)">
        <label>Operator</label>
        <select class="form-control" style="min-width: 220px"
                v-model="operator">
          <option v-for="operator in setting.operators"
                  v-bind:key="operator.name"
                  v-bind:value="operator.symbol">
            {{operator.name}}
          </option>
        </select>
      </div>
      <component v-bind:is="setting.component"
                 v-on:submit="onAddFilter"
                 ref="typeComponent"></component>
      <button class="btn btn-danger btn-sm float-right"
              v-on:click="show = false">
        Abbrechen
      </button>
      <button class="btn btn-primary btn-sm mr-1"
              v-on:click="onAddFilter">
        Hinzufügen
      </button>
    </b-popover>
  </div>
</template>
<script>
  import GridFilterTextComponent from './grid-filter-text'
  import GridFilterNumberComponent from './grid-filter-number'
  import GridFilterDateComponent from './grid-filter-date'
  import GridFilterBoolComponent from './grid-filter-bool'

  export default {
    props: ['property', 'type'],
    created() {
      this.checkType()
      this.resetOperator();
    },
    data() {
      return {
        id: this.$uuid.v4(),
        operator: null,
        show: false, //this.property == 'isInternal',
        settings: {
          'text': {
            component: GridFilterTextComponent,
            operators: [
              { name: 'enthält', symbol: '~' },
              { name: 'enthält nicht', symbol: '!~' },
              { name: 'beginnt', symbol: '^' },
              { name: 'beginnt nicht', symbol: '!^' },
              { name: 'endet', symbol: '$' },
              { name: 'endet nicht', symbol: '!$' },
              { name: 'gleich', symbol: '=' },
              { name: 'ungleich', symbol: '!=' },
            ]
          },
          'number': {
            component: GridFilterNumberComponent,
            operators: [
              { name: 'gleich', symbol: '=' },
              { name: 'ungleich', symbol: '!=' },
              { name: 'größer', symbol: '>' },
              { name: 'größer oder gleich', symbol: '>=' },
              { name: 'kleiner', symbol: '<' },
              { name: 'kleiner oder gleich', symbol: '<=' },
              { name: 'enthält', symbol: '~' },
              { name: 'enthält nicht', symbol: '!~' },
              { name: 'beginnt', symbol: '^' },
              { name: 'beginnt nicht', symbol: '!^' },
              { name: 'endet', symbol: '$' },
              { name: 'endet nicht', symbol: '!$' },
            ]
          },
          'date': {
            component: GridFilterDateComponent,
            operators: [
              { name: 'gleich', symbol: '=' },
              { name: 'ungleich', symbol: '!=' },
              { name: 'nach', symbol: '>' },
              { name: 'nach oder am', symbol: '>=' },
              { name: 'vor', symbol: '<' },
              { name: 'vor oder am', symbol: '<=' },
            ]
          },
          'bool': {
            component: GridFilterBoolComponent,
            uniquePerOperator: true,
            operators: [
              { name: 'gleich', symbol: '=' }
            ]
          }
        }
      }
    },
    computed: {
      setting() {
        if (!this.settings) return null;
        return this.settings[this.type] || null
      }
    },
    methods: {
      checkType() {
        if (!this.setting) {
          throw `Ungültiger type '${this.type}' für grid-control. Zulässig sind: [${Object.keys(this.settings)}]`
        }
      },
      onAddFilter() {
        var query = this.$refs.typeComponent.value
        if (query !== null && query !== '') {
          var filter = {
            property: this.property,
            type: this.type,
            query,
            operator: this.operator,
            push: !this.setting.uniquePerOperator
          }
          this.$emit('add', filter)
          this.show = false
        }
      },
      resetOperator() {
        if (this.hasOperators(1)) {
          this.operator = this.setting.operators[0].symbol
        }
      },
      hasOperators(count) {
        return Array.isArray(this.setting.operators) && this.setting.operators.length >= count
      }
    }
  }
</script>
