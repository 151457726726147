<template>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>Code</label>
        <b-form-input type="text" v-model="form.code" :formatter="formatUppercase" />
      </div>
      <div class="form-group">
        <label>Tier</label>
        <b-form-select v-model="form.tier" :options="tierOptions"></b-form-select>
      </div>
      <div class="form-group">
        <label>Valid From</label>
        <b-form-datepicker v-model="form.validFrom" v-bind="datepicker" />
      </div>
      <div class="form-group">
        <label>Valid To</label>
        <b-form-datepicker v-model="form.validTo" v-bind="datepicker" />
      </div>
      <hr>
      <h5>Apple</h5>
      <div class="form-group">
        <label>Offer Code</label>
        <b-form-input type="text" v-model="form.appleCode" :formatter="formatUppercase" />
      </div>
      <hr>
      <h5>Google</h5>
      <div class="form-group">
        <label>Product ID</label>
        <b-form-input type="text" v-model="form.googleProduct" />
      </div>
      <div class="form-group">
        <label>Base Plan ID</label>
        <b-form-input type="text" v-model="form.googleBaseplan" />
      </div>
      <div class="form-group">
        <label>Offer ID</label>
        <b-form-input type="text" v-model="form.googleOffer" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        form: {
          code: '',
          tier: 'PLUS',
          validFrom: '',
          validTo: '',
          appleCode: '',
          googleProduct: '',
          googleBaseplan: '',
          googleOffer: '',
        },
        datepicker: {
          startWeekday: 1,
        },
        tierOptions: [
          { value: 'BASIC', text: 'ownfin Basic' },
          { value: 'PLUS', text: 'ownfin Plus' },
          { value: 'PRO', text: 'ownfin Pro' },
          { value: 'ULTRA', text: 'ownfin Ultra' },
        ]
      }
    },
    methods: {
      formatUppercase(value){
        return value.toUpperCase()
      },
    },
  }
</script>