<template>
  <div>
    <navbar />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/navbar'

export default {
  components: {
    'navbar': NavbarComponent,
  }
}
</script>

<style>
.content {
  position: fixed;
  padding: 1.5rem 0px;
  overflow-y: scroll;
  top: 56px;
  height: calc(100% - 56px);
  width: 100%;
}
</style>